/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'

import coupleBeachPicnic from '../../images/heroes/couple-beach-picnic.jpg'

import { Phone } from 'emotion-icons/material'
import { StaticImage } from 'gatsby-plugin-image'
import DynamicCTACard from '../../components/DynamicCTACard'
import SeoCtaBar from '../../components/SeoCtaBar'

import {
  Banner,
  Columns,
  Column,
  Hero,
  LinkButton,
  Typography,
  VariableContent,
  useMapi,
} from '@leshen/gatsby-theme-leshen'
import { Layout } from '@leshen/gatsby-theme-contentful'

import footer from '../../components/FooterPPC'
import header from '../../components/HeaderPPC'

const Home = ({ data }) => {
  const { rotatedNumber } = useMapi(),
    brandyList = [
      'disclaimer-thirdpartymarketing',
      'disclaimer-plan',
      'disclaimer-evidence',
      'dsiclaimer-changenotice',
      'disclaimer-aetnabrand',
      'disclaimer-clicopyright',
      'disclaimer-incompletedescrip',
      'disclaimer-cmsmaterialid',
    ],
    layoutProps = {
      data: {
        contentfulPage: {
          header: header(),
          footer: footer(brandyList, data?.allBrandyDisclaimer?.edges),
          seo: {
            title:
              'Aetna Medicare | Find the right plan for your individual coverage',
            description: '',
            canonical: 'https://www.aetnamedicaredirect.com/',
            robots: 'follow,index',
          },
          path: '/lp/medicare-sep',
          promoCode: '88487',
          ringpool: 'MED',
          hideStickyCTA: false,
          spanish: false,
          sections: [
            {
              // Add any relevant section data
            },
          ],
        },
        site: {
          siteMetadata: {
            siteURL: 'https://www.aetnamedicaredirect.com/',
            siteName: 'aetnamedicaredirect',
            alternateName: 'aetnamedicaredirect',
            removeTrailingSlashes: true,
          },
        },
      },
      titleTemplate:
        'Aetna Medicare | Find the right plan for your individual coverage',
      defaultRobots: 'follow,index',
      main: (
        <>
          <div className="hero-content">
            <div className="wrapper">
              <Hero
                variant="full"
                className="leshen-hero"
                image={
                  <img
                    src={coupleBeachPicnic}
                    alt="elderly couple with dog and picnic basket at the beach"
                  />
                }
                mainContent={
                  <>
                    <Typography variant="h1" color="light">
                      Special Enrollment Periods for Special Circumstances{' '}
                    </Typography>

                    <DynamicCTACard />
                  </>
                }
              />
            </div>
          </div>

          <SeoCtaBar />

          <VariableContent
            alignMainContent="center"
            mainContent={
              <>
                <Typography variant="h2">
                  What are Special Enrollment Periods? Find out if you qualify.
                </Typography>

                <Typography variant="body">
                  A Special Enrollment Period (SEP) occurs based on a life
                  event, such as a move or loss of coverage. These special
                  circumstances may allow you to enroll in Medicare outside of
                  designated Annual Enrollment and General Enrollment periods.
                  Whatever your special circumstance may be, an SEP allows you
                  to enroll in a Medicare plan during this time.
                </Typography>

                <Typography variant="h5">
                  These are some of the most common ways to qualify for an SEP:
                </Typography>
              </>
            }
          >
            <Columns className="icon-two-column">
              <Column className="icon-column">
                <StaticImage
                  src="../../images/icons/purple-home.svg"
                  alt="home icon"
                  layout="fixed"
                  width={100}
                  height={100}
                  className="column-icon"
                />
              </Column>
              <Column className="content-column">
                <Typography variant="h5" color="primary">
                  You move permanent residences.
                </Typography>
                <Typography variant="body">
                  If you’re moving to an area where your current plan is not
                  covered, an SEP is available for you to switch to a new plan.
                  This is also the case if you move to an area that does cover
                  your plan, but also has more options for you to choose. This
                  SEP lasts up to two months.
                </Typography>
              </Column>
            </Columns>
            <Columns className="icon-two-column">
              <Column className="icon-column">
                <StaticImage
                  src="../../images/icons/purple-disaster.svg"
                  alt="disaster icon"
                  layout="fixed"
                  width={100}
                  height={100}
                  className="column-icon"
                />
              </Column>
              <Column className="content-column">
                <Typography variant="h5" color="primary">
                  You experience a weather-related emergency or major disaster.
                </Typography>
                <Typography variant="body">
                  Natural disasters, weather-related emergencies and major
                  disasters happen, whether we expect them or not—from floods to
                  earthquakes to global pandemics. If you find yourself caught
                  up in it, you may qualify for an SEP.
                </Typography>
              </Column>
            </Columns>
            <Columns className="icon-two-column">
              <Column className="icon-column">
                <StaticImage
                  src="../../images/icons/purple-cards.svg"
                  alt="cards icon"
                  layout="fixed"
                  width={100}
                  height={100}
                  className="column-icon"
                />
              </Column>
              <Column className="content-column">
                <Typography variant="h5" color="primary">
                  You have a chance to get other coverage.
                </Typography>
                <Typography variant="body">
                  If your employer or union allows you to enroll in their
                  coverage, this may qualify you for an SEP. You can also
                  qualify if you’re enrolling in drug coverage equal to
                  Medicare’s Program of All-inclusive Care for the Elderly
                  (PACE) plan.
                </Typography>
              </Column>
            </Columns>
            <Columns className="icon-two-column">
              <Column className="icon-column">
                <StaticImage
                  src="../../images/icons/purple-checklist.svg"
                  alt="checklist icon"
                  layout="fixed"
                  width={100}
                  height={100}
                  className="column-icon"
                />
              </Column>
              <Column className="content-column">
                <Typography variant="h5" color="primary">
                  Your plan changes its contract with Medicare.
                </Typography>
                <Typography variant="body">
                  Because Medicare Advantage plans come from the private sector,
                  there are contracts with Medicare that allow them to provide
                  you with your Parts A and B benefits. Sometimes, these
                  contracts are terminated by either party. This event qualifies
                  you for an SEP.
                </Typography>
              </Column>
            </Columns>
          </VariableContent>

          <VariableContent
            backgroundColor="lightGray"
            alignMainContent="left"
            mainContent={
              <>
                <Typography variant="h4">
                  There may be other special situations that qualify you for an
                  SEP. To learn more or to see if you’re eligible for an SEP,
                  our licensed agents are standing by to help!
                </Typography>
              </>
            }
          >
            <Banner
              backgroundColor="Primary"
              className="cta-banner column-desktop"
              mainContent={
                <>
                  <Typography
                    variant="h5"
                    style={{ color: 'white', marginBottom: '5px' }}
                  >
                    Talk to a Licensed Agent
                  </Typography>
                  <Typography
                    variant="body"
                    style={{ color: 'white', marginBottom: '10px' }}
                  >
                    TTY: 711, available 24/7
                  </Typography>
                  <LinkButton
                    variant="feature"
                    to={`tel:${rotatedNumber}`}
                    color="light"
                    className="margin-x-auto"
                    leftIcon={<Phone />}
                    requestId={require}
                  >
                    {rotatedNumber}
                  </LinkButton>
                </>
              }
            />
          </VariableContent>
        </>
      ),
    }

  return <Layout {...layoutProps} />
}

export default Home

export const query = graphql`
  query HomeQuery {
    allBrandyDisclaimer {
      edges {
        node {
          brandy_id
          text
          symbol
        }
      }
    }
  }
`
